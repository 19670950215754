<template>
    <div class="form-inline-input">
        <div class="code-box" id="code-box" >
            <p class="beforeVertify" style="color:#76777A;">按住滑块，拖动到最右边</p>
            <input type="text" name="code" class="code-input" />
            <p></p>
            <span v-if="!isSuccess">
            <img  src='../assets/images/arrow.png'>
          </span>
            <span v-if="isSuccess">
            <img  class="icon" src='../assets/images/system-complete.png' >
          </span>

        </div>

    </div>
</template>

<script>
    export default {
        data(){
            return{
                isSuccess:false,
                index:0
            }
        },
        methods:{
            getOffset(box,direction){
                var setDirection =  (direction == 'top') ? 'offsetTop' : 'offsetLeft' ;
                var offset =  box[setDirection];
                var parentBox = box.offsetParent;
                while(parentBox){
                    offset+=parentBox[setDirection];
                    parentBox = parentBox.offsetParent;
                }
                parentBox = null;
                return parseInt(offset);
            },
            moveCode(code,_this){

                var fn = {codeVluae : code};
                var box = document.querySelector("#code-box"),
                    progress = box.querySelector("p"),
                    codeInput = box.querySelector('.code-input'),
                    evenBox = box.querySelector("span");
                //默认事件
                var boxEven = ['mousedown','mousemove','mouseup'];
                //改变手机端与pc事件类型
                // if(typeof document.ontouchstart == 'object'){
                //   boxEven = ['touchstart','touchmove','touchend'];
                // }
                var goX,offsetLeft,deviation,evenWidth,endX;
                function moveFn(e){

                    if(!_this.isSuccess){
                        e.preventDefault();
                        e = (boxEven['0'] == 'touchstart') ? e.touches[0] : e || window.event;
                        endX = e.clientX - goX;
                        endX = (endX > 0) ? (endX > evenWidth) ? evenWidth : endX : 0;
                        if(endX > evenWidth * 0.95){
                            progress.innerText = '松开验证';
                            progress.style.backgroundColor = "#0091FF";
                        }else{
                            progress.innerText = '';
                            progress.style.backgroundColor = "#efefef";
                        }
                        progress.style.width = endX+deviation+'px';
                        evenBox.style.left = endX+'px';
                    }
                }
                function removeFn() {
                    console.log('是否成功：',_this.isSuccess);

                    if(!_this.isSuccess){
                        document.removeEventListener('mouseup',removeFn,false);
                        document.removeEventListener('mousemove',moveFn,false);
                    }

                    if(endX > evenWidth * 0.95){
                        console.log('验证成功');

                        _this.success('验证成功');
                        _this.isSuccess=true
                        progress.innerText = '验证成功';
                        progress.style.color = '#fff';
                        progress.style.width = evenWidth+deviation+'px';
                        evenBox.style.left = evenWidth+'px'
                        codeInput.value = fn.codeVluae;
                        evenBox.onmousedown = null;
                        //   _this.ruleForm.verification = true;
                    }else{
                        _this.isSuccess=false
                        _this.fail('验证失败')
                        progress.innerText = '按住滑块，拖动到最右边';
                        progress.style.width = '0';
                        // progress.style.height = '100%';
                        evenBox.style.left = '0px';
                    }
                };
                function getOffset(box,direction){
                    var setDirection =  (direction == 'top') ? 'offsetTop' : 'offsetLeft' ;
                    var offset =  box[setDirection];
                    var parentBox = box.offsetParent;
                    while(parentBox){
                        offset+=parentBox[setDirection];
                        parentBox = parentBox.offsetParent;
                    }
                    parentBox = null;
                    return parseInt(offset);
                };
                evenBox.addEventListener('mousedown', function(e) {

                    e = (boxEven['0'] == 'touchstart') ? e.touches[0] : e || window.event;
                    goX = e.clientX,
                        offsetLeft = getOffset(box,'left'),
                        deviation = this.clientWidth,
                        evenWidth = box.clientWidth - deviation,
                        endX;
                    document.addEventListener('mousemove',moveFn,false);
                    document.addEventListener('mouseup',removeFn,false);
                },false);
                fn.setCode = function(code){
                    if(code)
                        fn.codeVluae = code;
                }
                fn.getCode = function(){
                    return fn.codeVluae;
                }
                fn.resetCode = function(){
                    evenBox.removeAttribute('style');
                    progress.removeAttribute('style');
                    codeInput.value = '';
                };
                return fn;
            },
            success(type){
                this.index++
                if(this.index == 1){
                    console.log('第几次进来：',this.index);
                    this.$emit('result',0)
                }

            },
            fail(type){
                this.$emit('result',1)
            }
        },
        mounted(){
            var _this = this;
            // window.addEventListener('load',function(){
            //code是后台传入的验证字符串
            var code = "jsaidaisd656";
            let codeFn =  _this.moveCode(code,_this);

            // });
        },
        watch:{

        }
    }
</script>

<style scoped>
    .beforeVertify{
        text-align:center !important;
        color:#fff ;
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        top:0 !important;
        left:0 !important;

    }
    .success-border{
        border:#0091FF;
    }
    .icon{
        width: 14px !important;
        height: 14px !important;
        position: absolute !important;
        top:50% !important;
        left:50% !important;

        transform:translate(-50%,-50%) !important;
    }
    .form-inline-input{
        border:1px solid #dadada;
        border-radius:5px;
    }
    .form-inline-input input,
    .code-box{
        padding: 0 3px;
        width: 100%;
        height: 40px;
        color: #fff;
        /* text-shadow: 1px 1px 1px black; */
        background: #efefef;
        border: 0;
        border-radius: 5px;
        outline: none;
    }
    .code-box{
        position: relative;
    }
    .code-box p,
    .code-box span{
        display:block;
        position: absolute;
        left: 0;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius:5px;
        padding:0;
        margin:0;
    }
    .code-box span{
        width: 40px;
        background-color:#fff;
        font-size: 16px;
        cursor: pointer;
        margin-right:1px;
    }
    .code-box p{
        width: 0;
        background-color: #efefef;
        overflow: hidden;
        text-indent: -20px;
        transition: background 1s ease-in;
    }
    .code-box .code-input{
        display: none;
    }
    .code-box .code-input{
        display: none;
    }
    .form-inline-input{
        border:1px solid #dadada;
        border-radius:5px;
    }
    .form-inline-input input,
    .code-box{
        padding: 0 3px;
        width: 100%;
        height: 40px;
        color: #fff;
        /* text-shadow: 1px 1px 1px black; */
        background: #efefef;
        border: 0;
        border-radius: 5px;
        outline: none;
    }
    .code-box{
        position: relative;
    }
    .code-box p,
    .code-box span{
        display:block;
        position: absolute;
        left: 0;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        padding:0;
        margin:0;
    }
    .code-box span{
        width: 40px;
        background-color:#fff;
        font-size: 16px;
        cursor: pointer;
        margin-right:1px;
    }
    .code-box p{
        width: 0;
        background-color: #efefef;
        overflow: hidden;
        text-indent: -20px;
        transition: background 1s ease-in;
    }
    .code-box .code-input{
        display: none;
    }
    .code-box .code-input{
        display: none;
    }
</style>
