var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.routeInfo.token
    ? _c(
        "div",
        {
          staticClass: "login",
          style: {
            backgroundImage: "url(" + _vm.imgForm.customLoginBgimg + ")",
          },
          attrs: { id: "login" },
        },
        [
          _c("div", { staticClass: "login-div" }, [
            _c("div", { staticClass: "right-box" }, [
              _c(
                "div",
                { staticStyle: { "margin-right": "12%", "margin-top": "-6%" } },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", "text-align": "center" } },
                    [
                      _c("div", { staticClass: "logo-box" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.imgForm.logoImgType == "1"
                                ? _vm.imgForm.customLoginLogo
                                : _vm.imgForm.customLoginDefaultLogo,
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "logo-box-title",
                            staticStyle: { "font-size": "4.5vh" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  "margin-bottom": "-30px",
                                },
                              },
                              [_vm._v(_vm._s(_vm.imgForm.customLoginTitle))]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "input-box" }, [
                    !_vm.isSSOFlag
                      ? _c("div", { staticClass: "login_type" }, [
                          _c(
                            "span",
                            {
                              class: _vm.isLogin ? "type_active" : "",
                              on: {
                                click: function ($event) {
                                  return _vm.formalLogin("vertifyForm")
                                },
                              },
                            },
                            [_vm._v("密码登录")]
                          ),
                          _c(
                            "span",
                            {
                              class: _vm.verificationLogin ? "type_active" : "",
                              on: {
                                click: function ($event) {
                                  return _vm.formalToVertifyLogin("loginForm")
                                },
                              },
                            },
                            [_vm._v("验证码登录")]
                          ),
                        ])
                      : _vm._e(),
                    _vm.isLogin
                      ? _c("div", { key: "login" }, [
                          _vm.isSSOFlag &&
                          _vm.isSSOValidateFlag &&
                          _vm.hasLoggedIn
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "rs-row",
                                    [
                                      _c("rs-col", { attrs: { span: 16 } }, [
                                        _c(
                                          "span",
                                          { staticClass: "userName" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.userInfo.userName)
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "rs-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "rs-button",
                                            {
                                              staticClass: "margin20",
                                              attrs: {
                                                loading: _vm.loading,
                                                type: "text",
                                              },
                                              on: { click: _vm.logout },
                                            },
                                            [_vm._v("退出切换用户")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "rs-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "rs-button",
                                            {
                                              staticClass: "login_button",
                                              attrs: {
                                                loading: _vm.loading,
                                                type: "primary",
                                              },
                                              on: { click: _vm.getCode },
                                            },
                                            [_vm._v("登 录")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isSSOFlag && !_vm.isSSOValidateFlag
                            ? _c(
                                "div",
                                [
                                  _c("p", { staticClass: "messageTip" }, [
                                    _vm._v("SSO参数验证失败"),
                                  ]),
                                  _c("p", { staticClass: "errorTip" }, [
                                    _vm._v(_vm._s(_vm.ssoTip)),
                                  ]),
                                  _c(
                                    "rs-button",
                                    {
                                      staticClass: "login_button",
                                      attrs: {
                                        loading: _vm.loading,
                                        type: "primary",
                                      },
                                      on: { click: _vm.closePage },
                                    },
                                    [_vm._v("关 闭")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isSSOFlag ||
                          (_vm.isSSOFlag &&
                            !_vm.hasLoggedIn &&
                            _vm.isSSOValidateFlag)
                            ? _c(
                                "div",
                                { staticClass: "login_type" },
                                [
                                  _c(
                                    "rs-form",
                                    {
                                      ref: "loginForm",
                                      staticClass: "login_form",
                                      attrs: {
                                        "show-message": false,
                                        model: _vm.ruleForm,
                                        rules: _vm.rules,
                                        size: "small",
                                        "label-width": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "rs-form-item",
                                        {
                                          attrs: {
                                            label: "",
                                            prop: "loginNameOrMobile",
                                          },
                                        },
                                        [
                                          _c("rs-input", {
                                            staticClass: "login_input",
                                            staticStyle: {
                                              "background-color":
                                                "rgb(20,75,129) !important",
                                              color: "#fff",
                                              "border-radius": "4px !important",
                                            },
                                            attrs: {
                                              placeholder: "账号/手机号码",
                                              clearable: "",
                                              name: "loginNameOrMobile",
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.submitForm.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.ruleForm.loginNameOrMobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ruleForm,
                                                  "loginNameOrMobile",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "ruleForm.loginNameOrMobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "rs-form-item",
                                        {
                                          attrs: {
                                            label: "",
                                            prop: "password",
                                          },
                                        },
                                        [
                                          _c(
                                            "rs-input",
                                            {
                                              staticClass:
                                                "login_input font_white",
                                              staticStyle: {
                                                "background-color":
                                                  "rgb(20,75,129) !important",
                                                color: "#fff",
                                                "border-radius":
                                                  "4px !important",
                                              },
                                              attrs: {
                                                placeholder: "密码",
                                                type: !_vm.isView
                                                  ? "password"
                                                  : "text",
                                              },
                                              nativeOn: {
                                                keyup: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return _vm.submitForm.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.ruleForm.password,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "password",
                                                    $$v
                                                  )
                                                },
                                                expression: "ruleForm.password",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                class: _vm.isView
                                                  ? "rs-input__icon rs-icon-view"
                                                  : "eye-img",
                                                staticStyle: {
                                                  color: "#00bdf5",
                                                },
                                                attrs: { slot: "suffix" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isView = !_vm.isView
                                                  },
                                                },
                                                slot: "suffix",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "rs-button",
                                        {
                                          staticClass: "login_button",
                                          attrs: {
                                            loading: _vm.loading,
                                            type: "primary",
                                          },
                                          on: { click: _vm.submitForm },
                                        },
                                        [_vm._v("登 录")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.verificationLogin
                      ? _c(
                          "div",
                          [
                            _c(
                              "rs-form",
                              {
                                ref: "vertifyForm",
                                staticClass: "login_form margin-10",
                                attrs: {
                                  model: _vm.vertifyLoginList,
                                  rules: _vm.vertifyRules,
                                  "label-width": "",
                                },
                              },
                              [
                                _c(
                                  "rs-form-item",
                                  {
                                    attrs: { label: "", prop: "mobileVertify" },
                                  },
                                  [
                                    _c("rs-input", {
                                      staticClass: "login_input font-black",
                                      class: { border_red: _vm.mobileIsHas },
                                      staticStyle: {
                                        "background-color":
                                          "rgb(20,75,129) !important",
                                        color: "#fff",
                                        "border-radius": "4px !important",
                                      },
                                      attrs: {
                                        placeholder: "请输入手机号",
                                        clearable: "",
                                        type: "tel",
                                      },
                                      on: {
                                        clear: _vm.clear,
                                        focus: _vm.cleanall,
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.vertifyLogin.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.vertifyLoginList.mobileVertify,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.vertifyLoginList,
                                            "mobileVertify",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "vertifyLoginList.mobileVertify",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "rs-form-item",
                                  { attrs: { label: "", prop: "CodeVertify" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c("rs-input", {
                                          staticClass:
                                            "login_input font-black w59",
                                          staticStyle: {
                                            "background-color":
                                              "rgb(20,75,129) !important",
                                            color: "#fff",
                                            "border-radius": "4px !important",
                                          },
                                          attrs: {
                                            placeholder: "验证码",
                                            clearable: "",
                                            type: "text",
                                            name: "vertifyCode",
                                          },
                                          on: { focus: _vm.cleanall },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.vertifyLogin.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.vertifyLoginList.CodeVertify,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.vertifyLoginList,
                                                "CodeVertify",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "vertifyLoginList.CodeVertify",
                                          },
                                        }),
                                        !_vm.vertifyCountdownShow
                                          ? _c(
                                              "rs-button",
                                              {
                                                staticClass: "w30 getCount",
                                                staticStyle: {
                                                  "background-color":
                                                    "rgb(20,75,129) !important",
                                                  color: "#fff !important",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click:
                                                    _vm.sendSMSCodeVertifyLogin,
                                                },
                                              },
                                              [_vm._v("获取验证码")]
                                            )
                                          : _vm._e(),
                                        _vm.vertifyCountdownShow
                                          ? _c(
                                              "rs-button",
                                              {
                                                staticClass: "w30 getCount",
                                                staticStyle: {
                                                  "background-color":
                                                    "rgb(20,75,129) !important",
                                                  color: "#fff !important",
                                                },
                                                attrs: { type: "text" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.vertifyCountdownNum2
                                                  ) + "秒后重试"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "rs-button",
                                  {
                                    staticClass: "login_button",
                                    attrs: {
                                      loading: _vm.loading,
                                      type: "primary",
                                    },
                                    on: { click: _vm.vertifyLogin },
                                  },
                                  [_vm._v("登 录")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }