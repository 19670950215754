<template>
  <div v-if="!routeInfo.token" id="login" class="login" :style="{ backgroundImage: 'url(' + imgForm.customLoginBgimg + ')' }">
    <div class="login-div">
      <!-- <div class="left-box">
        <img v-if="imgForm.customLoginBgimg" :src="imgForm.loginImgType == '1' ? imgForm.customLoginBgimg : imgForm.customLoginBgDefaultImg" />
        <img v-else :src="imgForm.loginImgType == '1' ? imgForm.customLoginBgimg : imgForm.customLoginBgDefaultImg" />
      </div>-->
      <div class="right-box">
        <div style="margin-right: 12%;margin-top: -6%">
          <div style="width: 100%;text-align: center">
            <div class="logo-box">
              <img :src="imgForm.logoImgType == '1' ? imgForm.customLoginLogo : imgForm.customLoginDefaultLogo" />
              <div class="logo-box-title" style="font-size:4.5vh "><span style="float:right;margin-bottom: -30px">{{
                imgForm.customLoginTitle }}</span></div>
            </div>
            <!-- <p><img src="@/assets/images/login/hz.png" /></p>
          <p style="margin: 10px 0 30px"><img src="@/assets/images/login/py.png" /></p>-->
          </div>
          <div class="input-box">

            <div class="login_type" v-if="!isSSOFlag">
              <span :class="isLogin ? 'type_active' : ''" @click="formalLogin('vertifyForm')">密码登录</span>
              <span :class="verificationLogin ? 'type_active' : ''"
                @click="formalToVertifyLogin('loginForm')">验证码登录</span>
            </div>
            <!-- 账号密码登录 -->
            <div key="login" v-if="isLogin">
              <div v-if="isSSOFlag && isSSOValidateFlag && hasLoggedIn">
                <rs-row>
                  <rs-col :span="16">
                    <span class="userName">{{ userInfo.userName }}</span>
                  </rs-col>
                  <rs-col :span="8">
                    <rs-button class="margin20" :loading="loading" @click="logout" type="text">退出切换用户</rs-button>
                  </rs-col>
                  <rs-col :span="24">
                    <rs-button class="login_button" :loading="loading" @click="getCode" type="primary">登 录</rs-button>
                  </rs-col>
                </rs-row>
              </div>
              <div v-if="isSSOFlag && !isSSOValidateFlag">
                <p class="messageTip">SSO参数验证失败</p>
                <p class="errorTip">{{ ssoTip }}</p>
                <rs-button class="login_button" :loading="loading" @click="closePage" type="primary">关 闭</rs-button>
              </div>
              <!-- v-if 不是sso时显示账号密码； 是sso时，未来登录且第三方校验通过 - 显示账号密码 -->
              <div class="login_type" v-if="!isSSOFlag || (isSSOFlag && !hasLoggedIn && isSSOValidateFlag)">
                <rs-form :show-message="false" :model="ruleForm" :rules="rules" size="small" ref="loginForm" label-width
                  class="login_form">
                  <rs-form-item label prop="loginNameOrMobile">
                    <rs-input
                      style="background-color:rgb(20,75,129) !important;color: #fff;border-radius: 4px !important; "
                      placeholder="账号/手机号码" v-model.trim="ruleForm.loginNameOrMobile" clearable name="loginNameOrMobile"
                      class="login_input" @keyup.enter.native="submitForm"></rs-input>
                  </rs-form-item>
                  <rs-form-item label prop="password">
                    <rs-input
                      style="background-color:rgb(20,75,129) !important;color: #fff;border-radius: 4px !important;"
                      placeholder="密码" v-model="ruleForm.password" :type="!isView ? 'password' : 'text'"
                      class="login_input font_white" @keyup.enter.native="submitForm">
                      <i slot="suffix" style="color: #00bdf5"
                        :class="isView ? 'rs-input__icon rs-icon-view' : 'eye-img'" @click="isView = !isView"></i>
                    </rs-input>
                  </rs-form-item>
                  <rs-button class="login_button" :loading="loading" @click="submitForm" type="primary">登 录</rs-button>
                </rs-form>
              </div>
            </div>
            <!-- 验证码登录 -->
            <div v-if="verificationLogin">
              <rs-form :model="vertifyLoginList" :rules="vertifyRules" ref="vertifyForm" label-width
                class="login_form margin-10">
                <rs-form-item label prop="mobileVertify">
                  <rs-input
                    style="background-color:rgb(20,75,129) !important;color: #fff;border-radius: 4px !important;"
                    :class="{ border_red: mobileIsHas }" placeholder="请输入手机号"
                    v-model.trim="vertifyLoginList.mobileVertify" clearable type="tel" class="login_input font-black"
                    @keyup.enter.native="vertifyLogin" @clear="clear" @focus="cleanall"></rs-input>
                </rs-form-item>
                <rs-form-item label prop="CodeVertify">
                  <div style="display: flex;justify-content: space-between;align-items: center">
                    <rs-input
                      style="background-color:rgb(20,75,129) !important;color: #fff;border-radius: 4px !important;"
                      placeholder="验证码" v-model="vertifyLoginList.CodeVertify" clearable type="text"
                      class="login_input font-black w59" name="vertifyCode" @keyup.enter.native="vertifyLogin"
                      @focus="cleanall"></rs-input>
                    <rs-button style="background-color:rgb(20,75,129) !important;color: #fff !important;"
                      class="w30 getCount" type="text" v-if="!vertifyCountdownShow"
                      @click="sendSMSCodeVertifyLogin">获取验证码</rs-button>
                    <rs-button style="background-color:rgb(20,75,129) !important;color: #fff !important;"
                      class="w30 getCount" type="text" v-if="vertifyCountdownShow">{{ vertifyCountdownNum2
                      }}秒后重试</rs-button>

                  </div>
                </rs-form-item>
                <rs-button class="login_button" :loading="loading" @click="vertifyLogin" type="primary">登 录</rs-button>
              </rs-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <h4 v-if="imgForm.baNo" class="ba">
      <a class="text-color" :href="imgForm.baUrl" target="_blank">{{ imgForm.baNo }}</a>
    </h4> -->
  </div>
</template>
<script>
import Watermark from "@/utils/watermark.js";
import moment from 'moment'
import Api from '@/api/api'
import Secret from '@/utils/secret'
import Utils from '@/utils/utils'
import Refresh from '@/components/refresh'
import ImgApi from '@/api/imgSetter'
import utils from '../../utils/utils'
import
send
  from '@/api/singlepoint.js'
export default {
  components: { Refresh },
  data() {
    var loginName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入账号'))
      } else {
        if (this.ruleForm.password !== '') {
          this.$refs.loginForm.validateField('password')
        }
        callback()
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      isView: false, //密码是否可显示的
      ruleForm: {
        loginNameOrMobile: '',
        password: '',
        companyCode: '10001',
        loginSource: 'E_WEB',
        loginType: 'PASSWORD'
      },
      loading: false,
      rules: {
        loginNameOrMobile: [{ validator: loginName, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }]
      },
      isLogin: true,
      isForgetPassword: false,
      isViewRes: false,
      isViewResConfirm: false,
      isViewResModify: false,
      isViewResModifyConfirm: false,
      verificationLogin: false,
      vertifyCountdownShow: false,
      vertifyLoginList: {
        mobileVertify: '',
        CodeVertify: '',
        mallCode: ''
      },
      vertifyCountdownNum2: '', //验证码倒计时
      vertifyRules: {
        mobileVertify: [
          //  { validator: validateForgetVertifyLogin, trigger: 'blur' }],
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '手机格式不正确',
            trigger: 'blur'
          }
        ],
        CodeVertify: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          {
            pattern: /^\d{0,}$/,
            message: '验证码必须为数字值',
            trigger: 'blur'
          }
        ],
        mallCode: [{ required: true, message: '请输入企业代码', trigger: 'blur' }]
      },
      imgForm: {},
      userInfo: {}, //用户信息
      hasLoggedIn: false, //判断是否登录
      isSSOFlag: false, //标记是否是sso登录
      routeInfo: {}, //sso跳转到本地址时的路径入参
      isSSOValidateFlag: true, //标记sso校验结果
      ssoTip: ''
    }
  },
  created() {
    this.routeInfo = this.$route.query
    //sso校验
    if (this.routeInfo.hasOwnProperty('appId')) {
      this.validateIsSSO()
    }
    window.$message = this.$message // 消息框
    this.userInfo = Utils.getLocalStorage('userInfo')
    //获取路由中的token
    if (this.routeInfo.token) {
      this.sendQ(this.routeInfo.token)
    }
    this.autoLogin()
  },
  methods: {
    /**
     *
     * SSO校验，
     * 1、校验成功，表示是SSO登录,则在用户登录后，获取临时code并进行回调
     *  1.1、SSO校验，校验成功
     *    1.1.1、判断当前用户是否已经登录并进行页面交互调整
     *    1.1.2、已登录时，显示用户名，允许切换用户
     *    1.1.3、未登录时，页面可进行正常登录操作
     *  1.2、用户登录后,获取临时code并进行回调
     * 2、校验失败，页面显示对应关闭当前页按钮
     *
     * */
    //1、sso校验成功
    validateIsSSO() {
      this.isSSOFlag = false
      this.isSSOValidateFlag = true
      let { appId, callback, sign, state, timestamp } = this.routeInfo
      //验证第三方可信接口：1、有appId参数即表示是SSO登录 2、校验第三方信息是否真实有校
      if (
        this.routeInfo.hasOwnProperty('appId') &&
        this.routeInfo.hasOwnProperty('callback') &&
        this.routeInfo.hasOwnProperty('sign') &&
        this.routeInfo.hasOwnProperty('timestamp')
      ) {
        let params = {
          appId: appId, // 第三方ID
          timestamp: timestamp, // 第三方传递的时间戳
          sign: sign // 第三方传递的签名
        }
        this.isSSOFlag = true
        Api.ssoValidate(params).then((res) => {
          console.log('res:::', res.data.message)
          let reses = res.data
          if (reses.code == 200) {
            // 1.1、SSO校验，校验成功
            // 1.1.1、判断当前用户是否已经登录并进行页面交互调整`
          } else if (reses.code == -400400) {
            this.isSSOValidateFlag = false
            this.ssoTip = reses.message
          }
        })
      } else {
        console.log('不是SSO请求或第三方请求参数不全')
      }
    },
    sendQ(token) {
      const params = {
        token: token
      }
      send.send(params).then(res => {
        if (res.data.code === 200) {
          let userInfo = res.data.data
          Utils.setSession('userInfo', userInfo)
          Utils.setLocalStorage('userInfo', userInfo)
          Utils.setLocalStorage('aegeanUserInfo', res.data.data.sessionId)
          this.$router.push('/')
        }
      })
    },
    //获取临时code
    getCode() {
      Api.ssoGetCode({}).then((res) => {
        let code = res.data.data
        let { callback, state } = this.$route.query
        if (code) {
          let tempCallbackBaseUrl = callback.split('?')[0]
          let params = callback.split('?')[1]
          let url = Boolean(state) ? `${tempCallbackBaseUrl}?code=${code}&state=${state}` : `${tempCallbackBaseUrl}?code=${code}`
          window.location.href = `${url}${params ? '&' + params : ''}`
        }
      })
    },
    closePage() {
      window.location.href = 'about:blank'
      window.close()
    },
    // 自动登录
    autoLogin() {
      let { username, psw } = this.$route.query
      if (username && psw) {
        this.ruleForm.loginNameOrMobile = username
        this.ruleForm.password = psw
        this.submitForm()
      }
    },
    submitForm() {
      let that = this
      this.$refs['loginForm'].validate((valid) => {
        if (!valid) {
          this.$message.warning('请填写登录信息')
          return
        }
        this.loading = true
        let form = {
          ...that.ruleForm,
          password: Secret.Encrypt(that.ruleForm.password)
        }
        Api.login(form)
          .then((res) => {
            this.loading = false
            if (res.data.code === 200) {
              let requestData = res.data.data
              console.log(this.isSSOValidateFlag, 'requestData', requestData)

              Utils.setSession('userInfo', requestData)
              Utils.setLocalStorage('userInfo', requestData)
              Utils.setLocalStorage('aegeanUserInfo', res.data.data.sessionId)
              // 自动登录
              let { username, psw } = this.$route.query
              if (username && psw) {
                Utils.setLocalStorage('autoLogin', true)
                Utils.setLocalStorage('autoLoginUrl', window.location.href)
              }
              //1.2、用户登录后,获取临时code并进行回调
              console.log('isSSOFlag && this.isSSOValidateFlag', this.isSSOFlag && this.isSSOValidateFlag)
              if (this.isSSOFlag && this.isSSOValidateFlag) {
                this.getCode()
                return
              }
              this.$nextTick(function () {
                setTimeout(() => {
                  this.username = requestData.loginName + ' ' + requestData.userName + ' ' + moment().format('YYYY/MM/DD')
                  Watermark.set(this.username, document.getElementById('app'));
                }, 500)
              });
              that.$router.push('/')
            }
          })
          .catch((err) => {
            this.loading = false
          })
      })
    },
    async logout() {
      console.log('logout')
      await Api.logout()
      this.hasLoggedIn = false
      Utils.delLocalStorage('userInfo')
      Utils.delLocalStorage('aegeanUserInfo')
      Utils.delLocalStorage('buttonResource')
      Utils.delSessionStorage('userInfo')
      Utils.delSessionStorage('leftMenuInit')
      Utils.delSessionStorage('aegeanUserInfo')
      Utils.delSessionStorage('workBenchVoList')
    },
    formalToVertifyLogin(formName) {
      this.isLogin = false
      this.verificationLogin = true
      this.resetForm(formName)
    },
    formalLogin(formName) {
      this.isRefreshShow = false
      this.isLogin = true
      this.verificationLogin = false
      this.resetForm(formName)
    },
    resetForm(fromName) {
      this.$refs[fromName].resetFields()
      this.mobileIsHas = false
      this.mobileIsHasRgis = false
      this.mobileIsHasForget = false
      this.mobileIsHasLogin = false
      this.countdownShow = false
      this.modifyCountdownShow = false
      this.vertifyCountdownShow = false
      this.isView = false
      this.isViewRes = false
      this.isViewResConfirm = false
      this.isViewResModify = false
      this.isViewResModifyConfirm = false
      this.isRefreshShowForget = false
      this.isRefreshShow = false
      this.isRefreshShowRegis = false
    },
    clear(val) {
      this.mobileIsHas = false
      this.mobileIsHasRgis = false
      this.mobileIsHasForget = false
      this.mobileIsHasLogin = false
      console.log('清空的值：', val)
      //忘记密码
      if (this.isForgetPassword) {
        this.modifyCountdownShow = false
        this.isRefreshShowForget = false
      }
      //注册
      if (!this.isLogin) {
        this.countdownShow = false
        this.isRefreshShowRegis = false
      }
      //验证码登录
      if (this.verificationLogin) {
        this.isRefreshShow = false
        this.vertifyCountdownShow = false
      }
    },
    cleanall() {
      this.userError = false
      this.passwordError = false
    },
    sendSMSCodeVertifyLogin() {
      if (!this.vertifyLoginList.mobileVertify) {
        this.$message({
          message: '手机号码不能为空!',
          type: 'error'
        })
        return false
      }
      let reMobile = /^1[3456789]\d{9}$/
      if (!reMobile.test(this.vertifyLoginList.mobileVertify)) {
        console.log('手机格式不正确')
        this.$message({
          message: '手机号码格式错误!',
          type: 'error'
        })
        return false
      }
      if (!this.mobileIsHas) {
        if (this.verificationLogin) {
          // alert(111)
          let params = this.vertifyLoginList.mobileVertify
          this.vertifyCountdownShow = true
          this.vertifyCountdownNum = 60
          this.countdownFunvertical()
          Api.sendVerifyCodeByLogin(params)
            .then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  message: '验证码发送成功!',
                  type: 'success'
                })
              }
            })
            .catch(() => { })
        }
      }
    },
    vertifyLogin() {
      if (this.mobileIsHas) {
        return
      }
      this.$refs['vertifyForm'].validate((valid) => {
        if (valid) {
          this.login(2)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    countdownFunvertical() {
      // 验证码倒计时
      if (this.vertifyCountdownShow) {
        this.vertifyCountdownNum2 = this.vertifyCountdownNum--
        if (this.vertifyCountdownNum === -1) {
          this.refreshHiden()
          this.vertifyCountdownShow = false
          this.vertifyCountdownNum = 60
          return false
        }
        this.sleepFunVertical()
      }
    },
    sleepFunVertical() {
      // 延时程序
      setTimeout(() => {
        this.countdownFunvertical()
      }, 1005)
    },
    refreshHiden() {
      this.isRefreshShowRegis = false
      this.isRefreshShowForget = false
      this.isRefreshShow = false
    },
    login(type) {
      let self = this
      if (!self.loading) {
        self.loading = true
        let params
        //type 为1是账号密码登录
        //type 为2是手机验证登录
        params = {
          mobile: self.vertifyLoginList.mobileVertify,
          // 'loginType': "NOPASSWD",
          verifyCode: self.vertifyLoginList.CodeVertify,
          loginSource: 'E_MOBILE_CAPTCHA',
          loginType: 'NOPASSWD',
          companyCode: '10001'
        }
        Api.login(params)
          .then((res) => {
            self.loading = false
            self.userError = false
            self.passwordError = false
            self.isRefreshShow = false
            if (res.data.code === 200) {
              let userInfo = res.data.data
              Utils.setSession('userInfo', userInfo)
              Utils.setLocalStorage('userInfo', userInfo)
              Utils.setLocalStorage('aegeanUserInfo', res.data.data.sessionId)
              self.$router.push('/')
            }
          })
          .catch(() => {
            self.loading = false
          })
        // this.$router.push('/');
      }
    },
    async getImg() {
      console.log('1~')
      await ImgApi.findConfigByConfigGroup({
        configGroup: 1
      }).then((res) => {
        console.log('res:', res)
        this.imgForm = { ...this.imgForm, ...res.data.data }
        Utils.setSession('imgInfo', this.imgForm)
        Utils.setLocalStorage('imgInfo', this.imgForm)
        //SSO功能用： 1.1.1、判断当前用户是否已经登录并进行页面交互调整
        this.hasLoggedIn = res.data.data.hasLoggedIn == 'false' ? false : true
        // if(this.hasLoggedIn){
        //   this.$router.push('/')
        // }
        console.log('2~')
        this.$forceUpdate()
      })
      await ImgApi.findConfigByConfigGroup({
        configGroup: 2
      }).then((res) => {
        this.imgForm = { ...res.data.data, ...this.imgForm }
        Utils.setSession('imgInfo', this.imgForm)
        Utils.setLocalStorage('imgInfo', this.imgForm)
        this.$forceUpdate()
      })
      //如果不是sso方式访问登录页，则直接跳转
      if (!this.isSSOFlag && this.hasLoggedIn) {
        this.$router.push('/')
      }
      console.log('3~')
    }
  },
  mounted() {
    this.getImg()
  }
}
</script>
<style scoped lang="less">
.color-green {
  font-family: math;
  /* font-size: 14px; */
  color: #0091ff !important;
}

.login-another {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  height: 40px;
}

.eye-img {
  display: inline-block;
  height: 50px;
  width: 25px;
  text-align: center;
  line-height: 36px;
  background: url('../../assets/images/new-eye.png') no-repeat;
  background-position: center;
  background-size: 90%;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter,
.fade-leave-to

/* .slide-fade-leave-active for below version 2.1.8 */
  {
  transform: translateY(50px);
  opacity: 0;
}

#app {
  width: 100%;
  height: 100%;
}

.title_block {
  width: 100%;
  height: 100px;
  /* background: #fff; */
  display: flex;
  align-items: center;
  font-family: math;
  font-size: 24px;
  color: #444444;
  padding: 0 140px;
}

.title_font {
  border-left: 1px solid #979797;
  display: inline-block;
  height: 35px;
  margin-left: 24px;
  padding-left: 18px;
}

.title {
  font-family: math;
  font-size: 24px;
  color: #666666;
  letter-spacing: 2px;
  text-align: left;
  line-height: 31.46px;
  margin-bottom: 25px;
}

.login {
  width: 100%;
  min-width: 1100px;
  min-height: 620px;
  height: 100%;
  background-size: 100% 100%;
  // background: #ffffff;
  overflow: hidden;
}

.login-div {
  width: 100%;
  height: 100%;
  display: flex;
  // background: #ffffff;
  border-radius: 6px;
  box-shadow: 1px 1px 14px 0px rgba(131, 165, 234, 0.5);
}

.logo-box {
  width: 100%;
  height: 88px;
  text-align: center;
  line-height: 78px;
  margin-bottom: 20px;

  img {
    height: 90%;
    vertical-align: bottom;
    margin-right: 10px;
    margin-left: -13px;
  }

  .logo-box-title {
    font-family: math;
    font-size: 45px;
    color: #00bdf5;
    display: inline-block;
  }
}

.left-box {
  width: 600px;
  height: 100%;

  img {
    border-radius: 6px 0 0 6px;
    width: 100%;
    height: 100%;
  }
}

.right-box {
  /*flex: 1;*/
  position: relative;
  /* padding: 94px 60px 0;*/
  border-radius: 0 6px 6px 0;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.input-box {
  /*  position: absolute;
  left: 70%;
  top: 50%;
  transform: translate(-50%,-50%);*/
  background: url('../../assets/images/login/form-bg.png') no-repeat;
  background-size: 100% 100%;
  padding: 20px 40px;
  width: 428px;
  height: 355px;
}

.login_type {
  font-family: math;
  font-size: 22px;
  line-height: 28px;
  color: #999999;
  margin: 16px 0 34px 0;
}

.login_type span:first-child {
  cursor: pointer;
  padding-right: 6px;
  position: relative;
}

.login_type span:first-child:after {
  content: '';
  width: 1px;
  height: 16px;
  position: absolute;
  background: #d3d3d3;
  right: 0;
  top: 5px;
}

.login_type span:last-child {
  cursor: pointer;
  padding-left: 6px;
}

.type_active {
  color: #FFFFFF;
}

.logo_block {
  width: 185px;
  height: 40px;
  background: url('../../assets/images/sasslogo.png') no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.login_form .forgetPw {
  display: block;
}

.login_form {
  width: 100%;
}

/deep/ .login_form .rs-form-item .rs-form-item__content {
  height: 50px;
  line-height: 50px;
}

.login_input input {
  border: 0;
  background: #f5f5f5 !important;
  border-radius: 4px;
  color: #666;
}

.login_input input:focus {
  border-color: #1151d1 !important;
}

.rs-form-item {
  margin-bottom: 0;
}

.login_button {
  width: 100%;
  height: 50px;
  /*  font-family: math;*/
  font-size: 22px;
  color: #ffffff;
  margin-top: 28px;
  background: rgb(11, 161, 248);
  box-shadow: 0 1px 2px 0 rgba(69, 69, 69, 0.5);
  border-radius: 4px;
  border-radius: 4px;
  padding: 0;
}

.login_button_dis {
  width: 100%;
  height: 36px;
  font-family: math;
  font-size: 14px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.1);
  border: 0;
}

.w70 {
  width: 70%;
}

.w59 {
  // width: 66%;
}

.w30 {
  // width: 31%;
}

.font-black {
  color: rgba(0, 0, 0, 0.5);
}

.getCount {
  height: 50px;
  width: 50%;
  font-size: 18px !important;
  background: #ffffff;
  border: 1px solid #ededed;
  margin-left: 3%;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5) !important;
  letter-spacing: 0.17px;
}

/deep/.rs-form-item:after,
.rs-form-item:before {
  display: initial !important;
}

.right-info {
  width: 100%;
  position: absolute;
  bottom: 20px;
  text-align: center;
  color: #999999;

  a {
    color: #66bdff;
  }

  a:hover {
    color: #0091ea;
  }
}
</style>
<style>
.login_form .rs-input__inner {
  height: 50px;
  font-family: math;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.login_form .rs-form-item {
  margin-top: 29px;
}

.login_button .rs-button {
  border-radius: 0;
}

.rs-input__icon {
  transition: none;
}

/* chrom提示框 */
.chroms-tips-main {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6vh;
}

.ba {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.messageTip {
  color: #333333;
  font-size: 22px;
  margin-top: 20px;
}

.errorTip {
  color: red;
  font-size: 18px;
  margin-top: 20px;
}

.userName {
  display: inline-block;
  font-size: 22px;
  margin: 20px 0;
}

.margin20 {
  margin-top: 20px;
}
</style>
<!--kecx-->
<style lang="less">
.rs-input__inner {
  display: inline-flex !important;
  box-sizing: border-box !important;
  width: 100% !important;
  padding-right: 12px !important;
  padding-left: 12px !important;
  color: #fff !important;
  font-size: 16px !important;
  background-color: rgb(20, 75, 129) !important;
  border: 1px solid rgb(20, 75, 129) !important;
  border-radius: 4px !important;
  cursor: text !important;
}

.input__inner:hover {
  font-size: 16px !important;
  -webkit-box-shadow: 0 0 0 0px #0A324C !important;
  background-color: rgb(20, 75, 129) !important;
  box-shadow: 0 0 0 0px #0A324C !important;
}

.input__inner:focus {
  font-size: 16px !important;
  -webkit-box-shadow: 0 0 0 0px #0A324C !important;
  background-color: rgb(20, 75, 129) !important;
  box-shadow: 0 0 0 2px #0A324C !important;
  color: #fff !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  font-size: 16px !important;
  background-color: rgb(20, 75, 129) !important;
  -webkit-animation: autofill-fix1 1s infinite !important;
  /**利用动画方式将背景图遮挡住*/
  -webkit-text-fill-color: #fff !important;
  /**字体颜色可按需求定义*/
  color: #fff !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  font-size: 16px !important;
  background-color: rgb(20, 75, 129) !important;
  -webkit-box-shadow: 0 0 0px 1000px #144B81 inset !important;
  /**通过边框阴影遮挡住背景*/
  -webkit-text-fill-color: #fff !important;
  color: #fff !important;
}

input::-webkit-input-placeholder {
  border-color: #144B81 !important;
  color: #fff !important;
}

input::-moz-placeholder {
  border-color: #144B81 !important;
  color: #fff !important;
  /* Firefox 19+ */
}

input:-ms-input-placeholder {
  border-color: #144B81 !important;
  color: #fff !important;
  /* IE 10+ */
}

input:-moz-placeholder {
  border-color: #144B81 !important;
  color: #fff !important;
  /* Firefox 18- */
}

input::placeholder {
  border-color: #144B81 !important;
  color: #fff !important;
  /* 设置holdplace字体颜色为灰色 */
}

.rs-form-item__error {
  line-height: 20px !important;
  font-size: 13px !important;
}
</style>