var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-inline-input" }, [
    _c("div", { staticClass: "code-box", attrs: { id: "code-box" } }, [
      _c(
        "p",
        { staticClass: "beforeVertify", staticStyle: { color: "#76777A" } },
        [_vm._v("按住滑块，拖动到最右边")]
      ),
      _c("input", {
        staticClass: "code-input",
        attrs: { type: "text", name: "code" },
      }),
      _c("p"),
      !_vm.isSuccess
        ? _c("span", [
            _c("img", {
              attrs: { src: require("../assets/images/arrow.png") },
            }),
          ])
        : _vm._e(),
      _vm.isSuccess
        ? _c("span", [
            _c("img", {
              staticClass: "icon",
              attrs: { src: require("../assets/images/system-complete.png") },
            }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }